<i18n lang="yaml">
  pt:
    explore: 'Explorar'
    account: 'Conta'
    search: 'Buscar página (ctrl+k)'
    searchPlaceholder: 'Buscar página'
    studio: 'Estúdio'
    locale:
      pt: 'Português'
      en: 'Inglês'
    theme:
      light: 'Tema claro'
      dark: 'Tema escuro'
    logout: 'Sair'
  en:
    explore: 'Explore'
    account: 'Account'
    search: 'Find page (ctrl+k)'
    searchPlaceholder: 'Find page'
    studio: 'Studio'
    locale:
      pt: 'Portuguese'
      en: 'English'
    theme:
      light: 'Light theme'
      dark: 'Dark theme'
    logout: 'Log out'
</i18n>
<template>
  <deck-dock
    :items="items"
    position="bottom"
  />

  <SearchInput
    v-model:spotlight-open="spotlightOpen"
    spotlight
    :placeholder="t('searchPlaceholder')"
    :searchable-data="searchableData"
  />
</template>

<script lang="ts">
import ROUTES from '~/assets/javascript/constants/routes';
import SearchInput from '~/components/search-input';
import { getFirstViewIdFromCategory, SearchableItemBuilder, ViewItemBuilder, isIdPresentInRecursiveList } from '~/assets/javascript/utils';
import { mapGetters, mapState } from '~/assets/javascript/modules/vuex';
import type { DeckDockProps } from '~/deck/dock/index.vue';
export default {
  name: 'MemberDock',
  components: {
    SearchInput,
  },
  props: {
    onlyHelper: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ...mapState('viewsMenu', ['views']),
      ...mapState('userConfigs', ['favoriteAppIds']),
      ...mapGetters('workspace', [
        'currentCategory',
        'categoryHierarchy',
        'logoUrl',
      ]),
      zazos: useZazos(),
      t: useI18n().t,
    };
  },
  data() {
    return {
      spotlightOpen: false,
      isIntercomChatOpen: false,
      intercomUnreadMessagesCount: 0,
      displayIntercomButton: false,
    };
  },
  computed: {
    isAppIconActive() {
      return Boolean(this.$route.params?.viewId);
    },
    items() {
      const items: DeckDockProps['items'] = [{
        imageUrl: this.logoUrl || this.zazos.logoPath.value,
        text: this.$route.params.tenantSlug as string,
      }];

      if (!this.onlyHelper) {
        if (this.categoryHierarchy.length > 1 || this.$auth?.user?.role === 'admin') {
          items.push({
            icon: 'fa-compass',
            text: this.t('explore'),
            to: this.homePath,
          });
        }

        if (this.currentCategory) {
          items.push({
            icon: 'grid-2',
            text: 'App',
            active: this.isAppIconActive,
            to: this.localePath({
              name: ROUTES.member.view.routeName,
              params: {
                tenantSlug: this.$route.params.tenantSlug,
                viewId: getFirstViewIdFromCategory(this.currentCategory),
              },
            }),
          });
        }

        items.push({
          icon: 'fa-search',
          text: this.t('search'),
          active: this.spotlightOpen,
          onClick: () => {
            this.toggleSpotlight();
          },
        });
      }

      if (this.displayIntercomButton) {
        const intercomItem = {
          icon: 'fa-comment-question',
          text: this.$t('global.help'),
          active: this.isIntercomChatOpen,
          classSelector: 'js-intercom-launcher', // this is used by intercom custom launcher
          onClick: () => {
            const previousState = this.isIntercomChatOpen;
            this.isIntercomChatOpen = !previousState;

            if (previousState) {
              window.Intercom('hide');
            } else {
              window.Intercom('show');
            }
          },
        };

        if (this.intercomUnreadMessagesCount > 0) {
          intercomItem.badge = this.intercomUnreadMessagesCount;
          intercomItem.badgeColor = 'error';
        }

        items.push(intercomItem);
      }

      if (!this.onlyHelper) {
        if (this.hasStudioAccess) {
          items.push({
            icon: 'fa-pen-ruler',
            text: this.t('studio'),
            hideOnMobile: true,
            to: this.studioPath,
          });
        }

        if (this.favoriteAppIds.length > 0) {
          items.push({
            type: 'divider',
            hideOnMobile: true,
          });

          this
            .categoryHierarchy
            .filter(category => this.favoriteAppIds.includes(category.id) && category.views.length > 0)
            .forEach((category) => {
              const active = Boolean(this.$route.params.viewId) && isIdPresentInRecursiveList(category.views, this.$route.params.viewId);

              items.push({
                icon: category.category_icon,
                text: category.category_name,
                hideOnMobile: true,
                active,
                to: this.localePath({
                  name: ROUTES.member.view.routeName,
                  params: {
                    tenantSlug: this.$route.params.tenantSlug,
                    viewId: getFirstViewIdFromCategory(category),
                  },
                }),
              });
            });
        }
      }

      items.push({ type: 'divider' });

      const userIconValue = this.profileImgUrl ? this.profileImgUrl : 'fa-user';
      const userIconKey = this.profileImgUrl ? 'imageUrl' : 'icon';

      items.push({
        [userIconKey]: userIconValue,
        text: this.$auth.user.username,
        active: this.$route.path === this.accountConfigurationPath,
        items: [
          {
            text: this.t('account'),
            icon: 'fa-user-gear',
            to: this.accountConfigurationPath,
          },
          {
            text: this.changeLocaleText,
            icon: 'fa-language',
            to: this.toggleLocalePath,
          },
          {
            text: this.changeThemeText,
            icon: 'fa-palette',
            onClick: () => {
              this.toggleTheme();
            },
          },
          {
            text: this.t('logout'),
            icon: 'fa-sign-out-alt',
            onClick: () => {
              this.$auth.logoutUser();
            },
          },
        ],
      });

      return items;
    },
    studioPath() {
      if (this.$route.params.viewId) {
        return this.localePath({
          name: ROUTES.studio.view.routeName,
          params: {
            tenantSlug: this.$route.params.tenantSlug,
            [ROUTES.studio.view.viewId]: this.$route.params.viewId,
          },
        });
      }

      return this.localePath({
        name: ROUTES.studio.views.routeName,
        params: {
          tenantSlug: this.$route.params.tenantSlug,
        },
      });
    },
    homePath() {
      return this.localePath({
        name: ROUTES.member.routeName,
        params: {
          tenantSlug: this.$route.params.tenantSlug,
        },
      });
    },
    accountConfigurationPath() {
      return this.localePath({
        name: ROUTES.member.accountConfiguration.routeName,
        params: {
          tenantSlug: this.$route.params.tenantSlug,
        },
      });
    },
    profileImgUrl() {
      return this.$auth.user.profile_img_urls?.face;
    },
    changeThemeText() {
      return this.zazos.theme.value === 'dark' ? this.t('theme.light') : this.t('theme.dark');
    },
    changeLocaleText() {
      return this.$i18n.locale === 'en' ? this.t('locale.pt') : this.t('locale.en');
    },
    toggleLocalePath() {
      return this.switchLocalePath(this.$i18n.locale === 'en' ? 'pt' : 'en');
    },
    searchableViewBuilder() {
      return new ViewItemBuilder({
        localeRoute: this.localeRoute,
        $route: this.$route,
        currentEnvironment: 'member',
      });
    },
    searchableItemBuilder() {
      return new SearchableItemBuilder(this.searchableViewBuilder);
    },
    searchableData() {
      return this.searchableItemBuilder?.buildItems(this.views);
    },
    hasStudioAccess() {
      const allowedPlan = ['genius', 'custom'].includes(this.$auth.user?.workspace?.plan);
      return this.$auth.user.role === 'admin' && allowedPlan;
    },
  },
  async mounted() {
    this.displayIntercomButton = this.initializeIntercomCustomLauncher();

    const intercomInitializeInterval = setInterval(() => {
      if (this.displayIntercomButton) {
        clearInterval(intercomInitializeInterval);
      }

      const initialized = this.initializeIntercomCustomLauncher();

      if (initialized) {
        this.displayIntercomButton = true;
        clearInterval(intercomInitializeInterval);
      }
    }, 50);

    const intercomTimeout = setTimeout(() => {
      clearInterval(intercomInitializeInterval);
      clearTimeout(intercomTimeout);
    }, 30000);
  },
  methods: {
    initializeIntercomCustomLauncher() {
      if (!window.Intercom) return false;

      window.Intercom('onShow', () => {
        this.isIntercomChatOpen = true;
      });

      window.Intercom('onHide', () => {
        this.isIntercomChatOpen = false;
      });

      window.Intercom('onUnreadCountChange', (unreadCount: number) => {
        this.intercomUnreadMessagesCount = unreadCount;
      });

      return true;
    },
    toggleTheme() {
      this.zazos.theme.value = this.zazos.theme.value === 'dark' ? 'light' : 'dark';
    },
    toggleSpotlight() {
      this.spotlightOpen = !this.spotlightOpen;
    },
  },
};
</script>
